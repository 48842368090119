import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material';

import AppRoutes from './routes/Routes';
import Colors from './style/Colors';

const theme = createTheme({
  palette: {
    primary: {
      main: Colors.primary,
      contrastText: '#fff',
    },
    secondary: {
      main: Colors.secondary,
      contrastText: '#fff',
    },
  },
  typography: {
    fontFamily: ["Montserrat"],
    subtitle1: {
      fontSize: 18,
      fontWeight: 600
    },
    subtitle2: {
      fontSize: 16,
      fontWeight: 500
    },
    body1: {
      fontSize: 14,
      fontWeight: 500
    },
    body2: {
      fontSize: 12,
      fontWeight: 400
    },
    caption: {
      fontSize: 10,
      fontWeight: 300
    },
  },
});

function App() {

  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <Routes>
          {AppRoutes.map((route, index) => (
            <Route
              key={index}
              path={route.path}
              element={route.component}
            />
          ))}
        </Routes>
      </ThemeProvider>
    </BrowserRouter >
  );
}

export default App;

