import { Box, CardMedia, Container, Divider, Grid, Typography } from '@mui/material'
import Images from 'App/assets/images/Images'
import Colors from 'App/style/Colors'
import React, { Fragment } from 'react'

function Footer() {
  return (
    <Fragment>
      <Box sx={{
        backgroundColor: [Colors.black],
        py: 3,
        color: [Colors.white]
      }}>
        <Container >
          <Grid container sx={{ py: 3, }}>
            <Grid item md={3} data-aos="fade-right" data-aos-delay="250" data-aos-anchor-placement="bottom-bottom" >
              <CardMedia component="img" image={Images.logo} sx={{
                height: 'auto',
                objectFit: 'fill',
                width: '45%',
                mx: '0px'
              }} />
            </Grid>
            <Grid item md={6.5} data-aos="zoom-in" data-aos-delay="250" data-aos-anchor-placement="bottom-bottom" sx={{ justifyContent: 'center' }}>
              <Typography component="nav" sx={{ display: 'flex' }}>
                <Typography component="ul" sx={{
                  alignItems: 'center',
                  display: 'inline-block',
                  justifyContent: 'center',
                  py: 2,

                }}>
                  <Typography component="li" sx={{
                    listStyleType: 'none',
                    display: 'inline-block',
                    fontSize: '16px',
                    fontWeight: '500',
                    mr: '40px',
                    position: 'relative',
                    color: Colors.white,

                  }}>
                    <Typography component="p">
                      Terms of use
                    </Typography>
                    <Box sx={{
                      backgroundColor: [Colors.white],
                      borderRadius: '50%',
                      width: '4px',
                      height: '4px',
                      position: 'absolute',
                      right: '-20px',
                      bottom: '45%'
                    }} />
                  </Typography>
                  <Typography component="li" sx={{
                    listStyleType: 'none',
                    display: 'inline-block',
                    fontSize: '16px',
                    fontWeight: '500',
                    mr: '40px',
                    color: Colors.white,
                    position: 'relative',
                  }}>
                    <Typography component="p">
                      Privacy
                    </Typography>
                    <Box sx={{
                      backgroundColor: [Colors.white],
                      borderRadius: '50%',
                      width: '4px',
                      height: '4px',
                      position: 'absolute',
                      right: '-20px',
                      bottom: '45%'
                    }} />
                  </Typography>
                  <Typography component="li" sx={{
                    listStyleType: 'none',
                    display: 'inline-block',
                    fontSize: '16px',
                    fontWeight: '500',
                    mr: '40px',
                    position: 'relative',
                    color: Colors.white,
                  }}>
                    <Typography component="p">
                      Cookies
                    </Typography>
                    <Box sx={{
                      backgroundColor: [Colors.white],
                      borderRadius: '50%',
                      width: '4px',
                      height: '4px',
                      position: 'absolute',
                      right: '-20px',
                      bottom: '45%'
                    }} />
                  </Typography>
                  <Typography component="li" sx={{
                    listStyleType: 'none',
                    display: 'inline-block',
                    fontSize: '16px',
                    fontWeight: '500',
                    mr: '40px',
                    position: 'relative',
                    color: Colors.white,
                  }}>
                    <Typography component="p">
                      Contact
                    </Typography>
                    <Box sx={{
                      backgroundColor: [Colors.white],
                      borderRadius: '50%',
                      width: '4px',
                      height: '4px',
                      position: 'absolute',
                      right: '-20px',
                      bottom: '45%'
                    }} />
                  </Typography>
                  <Typography component="li" sx={{
                    listStyleType: 'none',
                    display: 'inline-block',
                    fontSize: '16px',
                    fontWeight: '500',
                    mr: '40px',
                  }}>
                    <Typography component="p">
                      Legal Notice
                    </Typography>
                  </Typography>
                </Typography>
              </Typography>
            </Grid>
            <Grid item md={2.5} data-aos="zoom-in" data-aos-delay="250" data-aos-anchor-placement="bottom-bottom">
              <Box sx={{
                display: 'flex',
              }}>
                <CardMedia component="img" image={Images.appstore} sx={{
                  width: '60%',
                  height: '50px',
                  objectFit: 'fill',
                  backgroundColor: 'none',
                  display: 'inline-block',
                }} />
                <CardMedia component="img" image={Images.playstore} sx={{
                  width: '60%',
                  height: '50px',
                  objectFit: 'fill',
                  backgroundColor: 'none',
                  display: 'inline-block',
                }} />
              </Box>
            </Grid>
          </Grid>
          <Divider />
          <Grid container>
            <Grid item md={12} data-aos="zoom-in" data-aos-delay="250" data-aos-anchor-placement="bottom-bottom" sx={{
              textAlign: 'center',
              pt: 3,
            }}>
              <Typography variant="p" sx={{ textAlign: 'center', color: Colors.white }}>
                Copyrights @2023 – Powered by Society Hood
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Fragment >
  )
}

export default Footer