import React, { Fragment, useEffect, useState } from 'react'
import Typography from '@mui/material/Typography'
import { Box, Button, CardMedia, Container, Grid, } from '@mui/material'
import Colors from 'App/style/Colors'
import { Link } from 'react-router-dom'
import Images from 'App/assets/images/Images'

function Header() {
  const [navbar, setNavbar] = useState(false);
  const changeBackground = () => {
    if (window.scrollY >= 100) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };
  window.addEventListener('scroll', changeBackground)
  return (
    <Fragment>
      {/* <Box sx={{
        width: '100%',
        height: 'auto',
        opacity: '1',
        position: 'fixed',
        zIndex: 99,
        backgroundColor: Colors.primary
      }}  >
        <Container >
          <Grid container>
            <Grid item md={5}>
              <Typography component="nav" sx={{
                py: '20px',
              }}>
                <Typography component="ul" sx={{
                  px: "0px",
                  textAlign: 'end'
                }}>
                  <Typography component="li" sx={{
                    listStyle: 'none',
                    display: 'inline-block',
                    fontSize: '16px',
                    fontWeight: '500',
                    px: '15px',
                    py: '15px',
                    textTransform: 'capitalize',
                    cursor: "pointer",
                    position: "relative",
                    color: [Colors.white],
                    ":hover": {
                      color: [Colors.primary],
                      ".underline": {
                        display: "block",
                        opacity: "1",
                      }
                    }
                  }}>
                    <Box className='underline' sx={{
                      position: "absolute",
                      transition: "0.5s ease-in-out",
                      width: "80%",
                      height: "3px",
                      background: [Colors.primary],
                      opacity: "0",
                      bottom: "0px",
                    }}>
                    </Box>
                    home
                  </Typography>
                  <Typography component="li" sx={{
                    listStyle: 'none',
                    display: 'inline-block',
                    fontSize: '16px',
                    fontWeight: '500',
                    textTransform: 'capitalize',
                    px: '15px',
                    py: '15px',
                    cursor: "pointer",
                    position: "relative",
                    color: [Colors.white],
                    ":hover": {
                      color: [Colors.primary],
                      ".underline": {
                        display: "block",
                        opacity: "1",
                      }
                    }
                  }}>
                    <Box className='underline' sx={{
                      position: "absolute",
                      transition: "0.5s ease-in-out",
                      width: "80%",
                      height: "3px",
                      background: [Colors.primary],
                      opacity: "0",
                      bottom: "0px",
                    }}>
                    </Box>
                    pages
                  </Typography>
                  <Typography component="li" sx={{
                    listStyle: 'none',
                    display: 'inline-block',
                    fontSize: '16px',
                    fontWeight: '500',
                    px: '15px',
                    py: '15px',
                    textTransform: 'capitalize',
                    cursor: "pointer",
                    position: "relative",
                    color: [Colors.white],
                    ":hover": {
                      color: [Colors.primary],
                      ".underline": {
                        display: "block",
                        opacity: "1",

                      }
                    }
                  }}>
                    <Box className='underline' sx={{
                      position: "absolute",
                      transition: "0.5s ease-in-out",
                      width: "80%",
                      height: "3px",
                      background: [Colors.primary],
                      opacity: "0",
                      bottom: "0px",
                    }}>
                    </Box>

                    services
                  </Typography>
                </Typography>
              </Typography>
            </Grid>
            <Grid item md={2} sx={{ pl: 2, }}>
              <Link to="/">
                <Box component="img" src={Images.logo} sx={{ py: '10px', width: "70%", justifyContent: 'center', alignItems: 'center' }} />
              </Link>
            </Grid>
            <Grid item md={5}>
              <Typography component="nav" sx={{
                py: '15px',
              }}>
                <Typography component="ul" sx={{ px: "0px" }}>
                  <Typography id='text' component="li" sx={{
                    listStyle: 'none',
                    display: 'inline-block',
                    fontSize: '16px',
                    fontWeight: '500',
                    px: '15px',
                    py: '15px',
                    cursor: "pointer",
                    position: "relative",
                    color: [Colors.white],
                    textTransform: 'capitalize',
                    ":hover": {
                      color: [Colors.primary],
                      ".underline": {
                        display: "block",
                        opacity: "1",
                      }
                    }
                  }}>
                    <Box className='underline' sx={{
                      position: "absolute",
                      transition: "0.5s ease-in-out",
                      width: "80%",
                      height: "3px",
                      background: [Colors.primary],
                      opacity: "0",
                      bottom: "0px",
                    }}>
                    </Box>
                    
                  </Typography>
                  <Typography component="li" sx={{
                    listStyle: 'none',
                    display: 'inline-block',
                    fontSize: '16px',
                    fontWeight: '500',
                    px: '15px',
                    py: '15px',
                    cursor: "pointer",
                    position: "relative",
                    color: [Colors.white],
                    ":hover": {
                      color: [Colors.primary],
                      ".underline": {
                        display: "block",
                        opacity: "1",
                      }
                    }
                  }}>
                    <Box className='underline' sx={{
                      position: "absolute",
                      transition: "0.5s ease-in-out",
                      width: "80%",
                      height: "3px",
                      background: [Colors.primary],
                      opacity: "0",
                      bottom: "0px",
                    }}>
                    </Box>
                    References
                  </Typography>
                  <Typography component="li" sx={{
                    listStyle: 'none',
                    display: 'inline-block',
                    fontSize: '16px',
                    fontWeight: '500',
                    px: '15px',
                    py: '15px',
                    cursor: "pointer",
                    position: "relative",
                    color: [Colors.white],
                    ":hover": {
                      color: [Colors.primary],
                      ".underline": {
                        display: "block",
                        opacity: "1",

                      }
                    }
                  }}>
                    <Box className='underline' sx={{
                      position: "absolute",
                      transition: "0.5s ease-in-out",
                      width: "80%",
                      height: "3px",
                      background: [Colors.primary],
                      opacity: "0",
                      bottom: "0px",
                    }}>
                    </Box>
                    <Button variant="outlined" sx={{ border: `2px solid ${Colors.white}`, color: [Colors.white], '&:hover': { border: `2px solid ${Colors.primary}`, color: [Colors.primary], } }}>
                      About Us
                    </Button>
                  </Typography>
                </Typography>
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Box> */}
      <Box className={navbar ? 'navbar active' : 'navbar'} sx={{
        width: '100%',
        height: 'auto',
        position: 'fixed',
        zIndex: 99,
        background: 'transparent'
      }}  >
        <Container >
          <Grid container >
            <Grid item md={2} sx={{ mx: 0 }}>
              <CardMedia component="img" image={Images.logo} sx={{
                height: 'auto',
                objectFit: 'fill',
                width: '50%',
                py: '10px',
                mx: '0px'
              }} />
            </Grid>
            <Grid item md={10} textAlign="center">
              <Typography component="nav" className={'navbar link'} sx={{
                py: '10px',
              }}>
                <Typography component="ul" className={'navbar link'} sx={{
                  textAlign: 'right',
                  color: Colors.white
                }}>
                  <Typography id='text' component="li" className={'navbar link'} sx={{
                    listStyle: 'none',
                    display: 'inline-block',
                    fontSize: '16px',
                    fontWeight: '500',
                    padding: '10px',
                    cursor: "pointer",
                    position: "relative",
                    color: [Colors.white],
                    textTransform: 'capitalize',
                    ":hover": {
                      color: [Colors.primary],
                      ".underline": {
                        display: "block",
                        opacity: "1",
                      }
                    }
                  }}>
                    <Box className='underline' sx={{
                      position: "absolute",
                      transition: "0.5s ease-in-out",
                      width: "100%",
                      height: "3px",
                      background: [Colors.primary],
                      opacity: "0",
                      bottom: "0px",
                    }}>
                    </Box>
                    society hood app
                  </Typography>
                  <Typography component="li" sx={{
                    listStyle: 'none',
                    display: 'inline-block',
                    fontSize: '16px',
                    fontWeight: '500',
                    padding: '10px',
                    cursor: "pointer",
                    textTransform: 'capitalize',
                    color: [Colors.white],
                    position: "relative",
                    ":hover": {
                      color: [Colors.primary],
                      ".underline": {
                        display: "block",
                        opacity: "1",
                      }
                    }
                  }}>
                    <Box className='underline' sx={{
                      position: "absolute",
                      transition: "0.5s ease-in-out",
                      width: "100%",
                      height: "3px",
                      background: [Colors.primary],
                      opacity: "0",
                      bottom: "0px",
                    }}>
                    </Box>
                    home
                  </Typography>
                  <Typography component="li" sx={{
                    listStyle: 'none',
                    display: 'inline-block',
                    fontSize: '16px',
                    fontWeight: '500',
                    padding: '10px',
                    cursor: "pointer",
                    position: "relative",
                    textTransform: 'capitalize',
                    color: [Colors.white],
                    ":hover": {
                      color: [Colors.primary],
                      ".underline": {
                        display: "block",
                        opacity: "1",
                      }
                    }
                  }}>
                    <Box className='underline' sx={{
                      position: "absolute",
                      transition: "0.5s ease-in-out",
                      width: "100%",
                      height: "3px",
                      background: [Colors.primary],
                      opacity: "0",
                      bottom: "0px",
                    }}>
                    </Box>
                    services
                  </Typography>
                  <Typography component="li" sx={{
                    listStyle: 'none',
                    display: 'inline-block',
                    fontSize: '16px',
                    fontWeight: '500',
                    padding: '10px',
                    cursor: "pointer",
                    position: "relative",
                    textTransform: 'capitalize',
                    color: [Colors.white],
                    ":hover": {
                      color: [Colors.primary],
                      ".underline": {
                        display: "block",
                        opacity: "1",
                      }
                    }
                  }}>
                    <Box className='underline' sx={{
                      position: "absolute",
                      transition: "0.5s ease-in-out",
                      width: "100%",
                      height: "3px",
                      background: [Colors.primary],
                      opacity: "0",
                      bottom: "0px",
                    }}>
                    </Box>
                    contact us
                  </Typography>
                  <Typography component="li" sx={{
                    listStyle: 'none',
                    display: 'inline-block',
                    fontSize: '16px',
                    fontWeight: '500',
                    padding: '10px',
                    cursor: "pointer",
                    textTransform: 'capitalize',
                    position: "relative",
                    ":hover": {
                      color: [Colors.primary],
                      ".underline": {
                        display: "block",
                        opacity: "1",
                      }
                    }
                  }}>
                    <Button variant="outlined" sx={{ border: `1px solid ${Colors.white}`, color: [Colors.white], '&:hover': { backgroundColor: [Colors.primary], color: Colors.white } }}>
                      about us
                    </Button>
                  </Typography>
                </Typography>
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Fragment>
  )
}

export default Header