import Home from "App/views/home/Home"


const AppRoutes = [
  {
    path: "/",
    component: <Home />,
  }
]

export default AppRoutes