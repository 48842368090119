import React, { useEffect, } from 'react'
import { Box, Button, CardMedia, Container, Divider, FormControl, Grid, TextField, Typography, } from '@mui/material'
import Images from 'App/assets/images/Images'
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Layout } from 'App/layout/Layout';
import Colors from 'App/style/Colors';
import { makeStyles } from '@mui/styles';
import Aos from 'aos'
import "aos/dist/aos.css";
import zIndex from '@mui/material/styles/zIndex';

const useStyle = makeStyles({
  link: {
    color: '#333',
    textDecoration: 'underline',
    '&:hover': {
      textDecoration: 'none',
      opacity: '.8',
      color: Colors.primary,
    }
  },
})

const ourservice = [
  {
    image: [Images.visitor],
    name: 'visitor',
  },
  {
    image: [Images.ebilling],
    name: 'e-billing',
  },
  {
    image: [Images.complain],
    name: 'complain',
  },
  {
    image: [Images.eform],
    name: 'e-form',
  },
  {
    image: [Images.event],
    name: 'event',
  },
  {
    image: [Images.facility],
    name: 'facility booking',
  },
  {
    image: [Images.discussion],
    name: 'discussion',
  },
  {
    image: [Images.epolling],
    name: 'e-polling',
  },
  {
    image: [Images.anncoument],
    name: 'anncoument',
  },
  {
    image: [Images.document],
    name: 'document',
  },
  {
    image: [Images.calender],
    name: 'calender',
  },
  {
    image: [Images.access],
    name: 'access',
  },
  {
    image: [Images.tutriol],
    name: 'tutriol',
  },
  {
    image: [Images.myresident],
    name: 'my resident',
  },
  {
    image: [Images.emergency],
    name: 'emergency',
  },
  {
    image: [Images.cafeteria],
    name: 'cafeteria',
  },
]
// const price = [
//   {
//     heading: 'starter',
//     price: '$19/mo',
//     packagename1: 'The Essentials +',
//     packagename2: 'Advanced Properties Widget',
//     packagename3: 'Reputation Management',
//     packagename4: 'Branded Automated Rental Analysis',
//     packagename5: 'Comprehensive Monthly Reporting !',
//     packagename6: 'Reporting Dashboard',
//   },
//   {
//     heading: 'premium',
//     price: '$49',
//     packagename1: 'The Essentials +',
//     packagename2: 'Advanced Properties Widget',
//     packagename3: 'Reputation Management',
//     packagename4: 'Branded Automated Rental Analysis',
//     packagename5: 'Comprehensive Monthly Reporting !',
//     packagename6: 'Reporting Dashboard',
//   },
//   {
//     heading: 'delux',
//     price: '$99',
//     packagename1: 'The Essentials +',
//     packagename2: 'Advanced Properties Widget',
//     packagename3: 'Reputation Management',
//     packagename4: 'Branded Automated Rental Analysis',
//     packagename5: 'Comprehensive Monthly Reporting !',
//     packagename6: 'Reporting Dashboard',
//   },
// ]

function Home() {
  useEffect(() => {
    Aos.init({
      duration: 2000
    });
  }, []);

  return (
    <Layout>
      {/* /================Banner== =========================/ */}
      {/* <CardMedia image={Images.banner2} sx={{
        height: '800px',
        objectFit: 'fill',
        width: '100%',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        position: 'relative',
      }}>
        <Container >
          <Box sx={{
            position: 'absolute',
            display: 'inline-block',
            zIndex: 2,
            width: 'auto',
            top: '30%',
            right: '4%',
          }}>
            <CardMedia component="img" image={Images.logo} sx={{
              width: '70%',
              margin: '0% auto'
            }} />
            <Typography component="p" data-aos="fade-up" data-aos-delay="250" data-aos-anchor-placement="bottom-bottom" sx={{
              fontSize: '30px',
              color: Colors.white,
              mt: 5,
            }}>
              Property Management System
            </Typography>
          </Box>
        </Container >
      </CardMedia> */}
      <Box sx={{ backgroundColor: Colors.secondary, }}>
        <Swiper sx={{ backgroundColor: Colors.danger }}
          centeredSlides={true}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          pagination={{
            clickable: true,
          }}
          modules={[Autoplay, Pagination]}
          className="mySwiper"
          spaceBetween={30}
          loop={true}
        >
          <SwiperSlide >
            <CardMedia image={Images.banner1} sx={{
              height: 'auto',
              objectFit: 'fill',
              width: '100%',
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              position: 'relative',
              WebkitClipPath: ' polygon(50% 0%, 100% 0, 100% 35%, 100% 85%, 49% 96%, 0 87%, 0% 35%, 0 0)'
            }}>
              <Box sx={{
                width: '100%',
                backgroundColor: 'rgba(31, 31, 31, 0.4)',
                py: '417px',
                position: 'relative',
              }}>
                <Box sx={{
                  position: 'absolute',
                  top: '190px',
                  left: '70px',
                  backgroundColor: Colors.primary
                }}>
                  <Typography component='p' sx={{
                    color: [Colors.white],
                    fontSize: '20px',
                    fontWeight: 600,
                    textTransform: 'uppercase',
                    padding: '15px 30px',
                  }}>
                    welcome to society hood
                  </Typography>
                </Box>
                <Box data-aos="fade-up" data-aos-delay="250" data-aos-anchor-placement="bottom-bottom" sx={{
                  position: 'absolute',
                  top: '280px',
                  left: '70px',
                  width: '50%',
                }}>
                  <Typography component='p' sx={{
                    color: [Colors.white],
                    fontSize: '70px',
                    fontWeight: '800',
                    textAlign: 'left',
                    textTransform: 'capitalize',
                    lineHeight: '100px',
                    mb: 0,


                  }}>
                    society
                    <Typography component='span' sx={{
                      color: [Colors.white],
                      fontSize: '70px',
                      fontWeight: '800',
                      textAlign: 'left',
                      textTransform: 'capitalize',
                      lineHeight: '100px',
                      mb: 0,
                      display: 'block'
                    }}>
                      management
                    </Typography>
                    <Typography component='span' sx={{
                      color: [Colors.white],
                      fontSize: '70px',
                      fontWeight: '800',
                      textAlign: 'left',
                      textTransform: 'capitalize',
                      lineHeight: '100px',
                      mb: 0,
                      display: 'block'
                    }}>
                      system
                    </Typography>
                  </Typography>
                </Box>
              </Box>
            </CardMedia>
          </SwiperSlide>
          <SwiperSlide >
            <CardMedia image={Images.banner2} sx={{
              height: 'auto',
              objectFit: 'fill',
              width: '100%',
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              position: 'relative',
              WebkitClipPath: ' polygon(50% 0%, 100% 0, 100% 35%, 100% 85%, 49% 96%, 0 87%, 0% 35%, 0 0)'
            }}>

              <Box sx={{
                width: '100%',
                backgroundColor: 'rgba(31, 31, 31, 0.4)',
                py: '417px',
                position: 'relative'
              }}>
                <Box sx={{
                  position: 'absolute',
                  top: '190px',
                  left: '70px',
                  backgroundColor: Colors.primary
                }}>
                  <Typography component='p' sx={{
                    color: [Colors.white],
                    fontSize: '20px',
                    fontWeight: 600,
                    textTransform: 'uppercase',
                    padding: '15px 30px',
                  }}>
                    welcome to society hood
                  </Typography>
                </Box>
                <Box data-aos="fade-up" data-aos-delay="250" data-aos-anchor-placement="bottom-bottom" sx={{
                  position: 'absolute',
                  top: '280px',
                  left: '70px',
                  width: '50%',
                }}>
                  <Typography component='p' sx={{
                    color: [Colors.white],
                    fontSize: '70px',
                    fontWeight: '800',
                    textAlign: 'left',
                    textTransform: 'capitalize',
                    lineHeight: '100px',
                    mb: 0,


                  }}>
                    society
                    <Typography component='span' sx={{
                      color: [Colors.white],
                      fontSize: '70px',
                      fontWeight: '800',
                      textAlign: 'left',
                      textTransform: 'capitalize',
                      lineHeight: '100px',
                      mb: 0,
                      display: 'block'
                    }}>
                      management
                    </Typography>
                    <Typography component='span' sx={{
                      color: [Colors.white],
                      fontSize: '70px',
                      fontWeight: '800',
                      textAlign: 'left',
                      textTransform: 'capitalize',
                      lineHeight: '100px',
                      mb: 0,
                      display: 'block'
                    }}>
                      system
                    </Typography>
                  </Typography>
                </Box>
              </Box>
            </CardMedia>
          </SwiperSlide>
          <SwiperSlide >
            <CardMedia image={Images.banner3} sx={{
              height: 'auto',
              objectFit: 'fill',
              width: '100%',
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              position: 'relative',
              WebkitClipPath: ' polygon(50% 0%, 100% 0, 100% 35%, 100% 85%, 49% 96%, 0 87%, 0% 35%, 0 0)'
            }}>
              <Box sx={{
                width: '100%',
                backgroundColor: 'rgba(31, 31, 31, 0.4)',
                py: '417px',
                position: 'relative'
              }}>
                <Box sx={{
                  position: 'absolute',
                  top: '190px',
                  left: '70px',
                  backgroundColor: Colors.primary
                }}>
                  <Typography component='p' sx={{
                    color: [Colors.white],
                    fontSize: '20px',
                    fontWeight: 600,
                    textTransform: 'uppercase',
                    padding: '15px 30px',
                  }}>
                    welcome to society hood
                  </Typography>
                </Box>
                <Box data-aos="fade-up" data-aos-delay="250" data-aos-anchor-placement="bottom-bottom" sx={{
                  position: 'absolute',
                  top: '280px',
                  left: '70px',
                  width: '50%',
                }}>
                  <Typography component='p' sx={{
                    color: [Colors.white],
                    fontSize: '70px',
                    fontWeight: '800',
                    textAlign: 'left',
                    textTransform: 'capitalize',
                    lineHeight: '100px',
                    mb: 0,


                  }}>
                    society
                    <Typography component='span' sx={{
                      color: [Colors.white],
                      fontSize: '70px',
                      fontWeight: '800',
                      textAlign: 'left',
                      textTransform: 'capitalize',
                      lineHeight: '100px',
                      mb: 0,
                      display: 'block'
                    }}>
                      management
                    </Typography>
                    <Typography component='span' sx={{
                      color: [Colors.white],
                      fontSize: '70px',
                      fontWeight: '800',
                      textAlign: 'left',
                      textTransform: 'capitalize',
                      lineHeight: '100px',
                      mb: 0,
                      display: 'block'
                    }}>
                      system
                    </Typography>
                  </Typography>
                </Box>
              </Box>
            </CardMedia>
          </SwiperSlide>
        </Swiper >
      </Box>
      {/* /================our services===========================/ */}
      <Box sx={{
        py: 6,
        width: '100%',
        height: "auto",
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'right bottom',
        backgroundSize: '100%',
        backgroundColor: Colors.secondary,
        borderBottom: '1px solid #eee',
        overflow: 'hidden',
      }
      }>
        <Container >
          <Grid container >
            <Grid item md={12} sx={{
              padding: '50px 20px 0px 20px',
              textAlign: 'left',
            }}>
              <Typography variant="h3" sx={{
                fontWeight: 'bold',
                fontSize: '30px',
                letterSpacing: '1px',
                color: [Colors.primary],
                textTransform: 'capitalize',
                ":hover": {
                  opacity: .8
                }
              }}>
                society hood
              </Typography>
              <Box sx={{
                width: '100px',
                height: '3px',
                borderRadius: '2px',
                backgroundColor: [Colors.primary],
                my: 2,
              }} />
              <Typography component="h4" data-aos="fade-right" data-aos-delay="250" data-aos-anchor-placement="bottom-bottom" sx={{
                py: 2,
                fontFamily: 'sans-serif',
                fontSize: '32px',
                textTransform: 'capitalize',
              }}>
                an app for all society management solutions
              </Typography>

            </Grid>
          </Grid>
          <Grid container >
            <Grid item md={7} data-aos="zoom-in" data-aos-delay="250" data-aos-anchor-placement="bottom-bottom" sx={{ py: 2, px: 1, }}>
              <Grid container  >
                {ourservice.map((item, index) => (
                  <Grid item md={3} key={index} sx={{ padding: '5px', }}>
                    <Box sx={{ padding: '5px', }}>
                      <Box sx={{ justifyContent: 'center', justifyContent: 'center', display: 'flex' }}>
                        <Box component="img" src={item.image} sx={{
                          width: '70px',
                        }} />
                      </Box>
                      <Typography component='p' sx={{
                        fontSize: '14px',
                        lineHeight: 3,
                        fontWeight: '400',
                        letterSpacing: 1,
                        textTransform: "capitalize",
                        textAlign: 'center'

                      }}>
                        {item.name}
                      </Typography>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </Grid>
            <Grid item md={5} data-aos="fade-left" data-aos-delay="250" data-aos-anchor-placement="bottom-bottom" sx={{ pt: 2, px: 1, float: 'right', position: 'relative' }}>
              <CardMedia component='img' image={Images.phoneimg} sx={{
                position: "absolute",
                top: -140,
                height: '850px',
              }} />
            </Grid>
          </Grid>
        </Container>
      </Box >
      {/* /================package=========================/ */}
      < Box sx={{
        py: 6,
        borderBottom: '1px solid #eee',
        backgroundColor: Colors.offwhite,
      }}>
        <Container >
          <Grid container >
            <Grid item md={12} sx={{
              p: 1.5,
            }}>
              <Typography component='p' sx={{
                fontWeight: 'bold',
                fontSize: '20px',
                letterSpacing: '1px',
                color: Colors.primary,
                textTransform: 'capitalize',
                ":hover": {
                  opacity: .8
                }
              }}>
                check our pricing
              </Typography>
              <Box sx={{
                width: '100px',
                height: '3px',
                borderRadius: '2px',
                backgroundColor: [Colors.primary],
                my: 2,
              }} />
              <Typography component="h4" data-aos="fade-right" data-aos-delay="250" data-aos-anchor-placement="bottom-bottom" sx={{
                py: 2,
                fontFamily: 'sans-serif',
                fontSize: '32px',
                textTransform: 'capitalize',
              }}>
                See why our clients see us as a reliable long-term partner
              </Typography>
            </Grid>
          </Grid>
          <Grid container >
            <Grid item md={4} data-aos="zoom-in" data-aos-delay="250" sx={{
              px: 2,
              py: 3,
            }}>
              {/* <Box sx={{
                '&:hover': {
                  backgroundColor: Colors.primary,
                  borderTopLeftRadius: '10px',
                  transform: "translate(10px, 20px)",
                  transitionDelay: '.26s',
                }
              }}> */}
              <Box sx={{
                backgroundColor: [Colors.offwhite],
                borderRadius: '10px',
                padding: '48px 20px 20px',
                height: 'auto',
                border: '1px solid #e5e5e5',
                textAlign: 'center',
                transition: "all 0.3s ease-in",
                // '&:hover': {
                //   transform: "translate(10px, 20px)",
                //   transitionDelay: '.26s',
                // }
              }}>
                <Typography component="p" sx={{
                  fontSize: '33px',
                  fontWeight: 'bold',
                  letterSpacing: 1,
                  mt: '15px',
                  mb: '20px',
                  textTransform: 'uppercase'
                }} >
                  Grow
                </Typography>
                <Button variant="contained" sx={{
                  textTransform: 'uppercase',
                  fontSize: '17px',
                  fontWeight: '500',
                  letterSpacing: 1,
                  borderRadius: '36px',
                  px: '40px',
                  width: '225px',
                  textAlign: 'center',
                  color: '1px solid #fff',
                  border: '1px solid #008080',
                  backgroundColor: Colors.primary,
                  '&:hover': {
                    backgroundColor: Colors.white,
                    color: Colors.primary,
                  }
                }}>
                  choose plan
                </Button>
                <Box sx={{ my: 5 }}>
                  <Typography component="p" sx={{
                    mb: '20px',
                    lineHeight: 1,
                    fontSize: '36px',
                    fontWeight: 400,
                    color: Colors.primary,
                  }}>
                    $599/mo
                    <Typography variant='body1' component="span" sx={{
                      display: 'block',
                      color: Colors.tertiary,
                      mt: '5px',
                    }}>
                      per month
                    </Typography>
                  </Typography>
                </Box >
                <Typography component="ul" sx={{
                  textAlign: 'left',
                  listStyle: 'none',
                  padding: '0px',
                }}>
                  <Typography variant='subtitle1' component="li" sx={{
                    padding: "15px",
                    borderTop: "1px solid #ccc",
                    margin: '0px',
                  }}>
                    The Essentials +
                  </Typography>
                  <Typography component="li" sx={{
                    padding: "15px",
                    borderTop: "1px solid #ccc",
                    margin: '0px',
                    fontSize: '15px',
                  }}>
                    Advanced Properties Widget
                  </Typography>
                  <Typography component="li" sx={{
                    padding: "15px",
                    borderTop: "1px solid #ccc",
                    margin: '0px',
                    fontSize: '15px',
                  }}>
                    Reputation Management
                  </Typography>
                  <Typography component="li" sx={{
                    padding: "15px",
                    borderTop: "1px solid #ccc",
                    margin: '0px',
                    fontSize: '15px',
                  }}>
                    Branded Automated Rental Analysis
                  </Typography>
                  <Typography component="li" sx={{
                    padding: "15px",
                    borderTop: "1px solid #ccc",
                    margin: '0px',
                    fontSize: '15px',
                    color: '#000f14'
                  }}>
                    Comprehensive Monthly Reporting !
                  </Typography>
                  <Typography component="li" sx={{
                    padding: "15px",
                    borderTop: "1px solid #ccc",
                    margin: '0px',
                    fontSize: '15px',
                    color: '#000f14'
                  }}>
                    Reporting Dashboard
                  </Typography>
                </Typography>
              </Box>
            </Grid>
            <Grid item md={4} data-aos="zoom-in" data-aos-delay="250" sx={{
              px: 2,
              py: 3,
              position: 'relative',
            }}>
              <Box sx={{
                position: 'absolute',
                top: '60px',
                right: '48px',
                width: '30%',

                minWidth: '92px',
                textAlign: 'center',
                backgroundColor: Colors.primary,
                transform: 'rotate(27deg) translate(25%,-26px)',
                transformOrigin: 'center',
              }}>
                <Typography component="p" sx={{
                  py: '6px',
                  color: Colors.white,
                  fontSize: '10px',
                  textTransform: 'uppercase',
                  fontWeight: '600',
                }}>
                  Most Popular
                </Typography>
              </Box>
              {/* <Box sx={{
                '&:hover': {
                  backgroundColor: Colors.primary,
                  borderTopLeftRadius: '10px',
                  transform: "translate(10px, 20px)",
                  transitionDelay: '.26s',
                }
              }}> */}
              <Box sx={{
                backgroundColor: [Colors.offwhite],
                borderRadius: '10px',
                padding: '48px 20px 20px',
                height: 'auto',
                border: '1px solid #e5e5e5',
                textAlign: 'center',
                transition: "all 0.3s ease-in",
                // '&:hover': {
                //   transform: "translate(10px, 20px)",
                //   transitionDelay: '.26s',
                // }

              }}>
                <Typography component="p" sx={{
                  fontSize: '33px',
                  fontWeight: 'bold',
                  letterSpacing: 1,
                  mt: '15px',
                  mb: '20px',
                  textTransform: 'uppercase'
                }} >
                  Accelerate
                </Typography>
                <Button variant="contained" sx={{
                  textTransform: 'uppercase',
                  fontSize: '17px',
                  fontWeight: '500',
                  letterSpacing: 1,
                  borderRadius: '36px',
                  px: '40px',
                  width: '225px',
                  textAlign: 'center',
                  color: '1px solid #fff',
                  border: '1px solid #008080',
                  backgroundColor: Colors.primary,
                  '&:hover': {
                    backgroundColor: Colors.white,
                    color: Colors.primary,
                  }
                }}>
                  choose plan
                </Button>
                <Box sx={{ my: 5 }}>
                  <Typography component="p" sx={{
                    mb: '20px',
                    lineHeight: 1,
                    fontSize: '36px',
                    fontWeight: 400,
                    color: Colors.primary,
                  }}>
                    $599/mo
                    <Typography variant='body1' component="span" sx={{
                      display: 'block',
                      color: Colors.tertiary,
                      mt: '5px',
                    }}>
                      per month
                    </Typography>
                  </Typography>
                </Box >
                <Typography component="ul" sx={{
                  textAlign: 'left',
                  listStyle: 'none',
                  padding: '0px',
                }}>
                  <Typography variant='subtitle1' component="li" sx={{
                    padding: "15px",
                    borderTop: "1px solid #ccc",
                    margin: '0px',
                  }}>
                    The Essentials +
                  </Typography>
                  <Typography component="li" sx={{
                    padding: "15px",
                    borderTop: "1px solid #ccc",
                    margin: '0px',
                    fontSize: '15px',
                  }}>
                    Advanced Properties Widget
                  </Typography>
                  <Typography component="li" sx={{
                    padding: "15px",
                    borderTop: "1px solid #ccc",
                    margin: '0px',
                    fontSize: '15px',
                  }}>
                    Reputation Management
                  </Typography>
                  <Typography component="li" sx={{
                    padding: "15px",
                    borderTop: "1px solid #ccc",
                    margin: '0px',
                    fontSize: '15px',
                  }}>
                    Branded Automated Rental Analysis
                  </Typography>
                  <Typography component="li" sx={{
                    padding: "15px",
                    borderTop: "1px solid #ccc",
                    margin: '0px',
                    fontSize: '15px',
                    color: '#000f14'
                  }}>
                    Comprehensive Monthly Reporting !
                  </Typography>
                  <Typography component="li" sx={{
                    padding: "15px",
                    borderTop: "1px solid #ccc",
                    margin: '0px',
                    fontSize: '15px',
                    color: '#000f14'
                  }}>
                    Reporting Dashboard
                  </Typography>
                </Typography>
              </Box>

            </Grid>
            <Grid item md={4} data-aos="zoom-in" data-aos-delay="250" sx={{
              px: 2,
              py: 3,
            }}>
              {/* <Box sx={{
                '&:hover': {
                  backgroundColor: Colors.primary,
                  borderTopLeftRadius: '10px',
                  transform: "translate(10px, 20px)",
                  transitionDelay: '.26s',
                }
              }}> */}
              <Box sx={{
                backgroundColor: [Colors.offwhite],
                borderRadius: '10px',
                padding: '48px 20px 20px',
                height: 'auto',
                border: '1px solid #e5e5e5',
                textAlign: 'center',
                transition: "all 0.3s ease-in",
                // '&:hover': {
                //   transform: "translate(10px, 20px)",
                //   transitionDelay: '.26s',
                // }
              }}>
                <Typography component="p" sx={{
                  fontSize: '33px',
                  fontWeight: 'bold',
                  letterSpacing: 1,
                  mt: '15px',
                  mb: '20px',
                  textTransform: 'uppercase'
                }} >
                  Market Leader
                </Typography>
                <Button variant="contained" sx={{
                  textTransform: 'uppercase',
                  fontSize: '17px',
                  fontWeight: '500',
                  letterSpacing: 1,
                  borderRadius: '36px',
                  px: '40px',
                  width: '225px',
                  textAlign: 'center',
                  color: '1px solid #fff',
                  border: '1px solid #008080',
                  backgroundColor: Colors.primary,
                  '&:hover': {
                    backgroundColor: Colors.white,
                    color: Colors.primary,
                  }
                }}>
                  choose plan
                </Button>
                <Box sx={{ my: 5 }}>
                  <Typography component="p" sx={{
                    mb: '20px',
                    lineHeight: 1,
                    fontSize: '36px',
                    fontWeight: 400,
                    color: Colors.primary,
                  }}>
                    $599/mo
                    <Typography variant='body1' component="span" sx={{
                      display: 'block',
                      color: Colors.tertiary,
                      mt: '5px',
                    }}>
                      per month
                    </Typography>
                  </Typography>
                </Box >
                <Typography component="ul" sx={{
                  textAlign: 'left',
                  listStyle: 'none',
                  padding: '0px',
                }}>
                  <Typography variant='subtitle1' component="li" sx={{
                    padding: "15px",
                    borderTop: "1px solid #ccc",
                    margin: '0px',
                  }}>
                    The Essentials +
                  </Typography>
                  <Typography component="li" sx={{
                    padding: "15px",
                    borderTop: "1px solid #ccc",
                    margin: '0px',
                    fontSize: '15px',
                  }}>
                    Advanced Properties Widget
                  </Typography>
                  <Typography component="li" sx={{
                    padding: "15px",
                    borderTop: "1px solid #ccc",
                    margin: '0px',
                    fontSize: '15px',
                  }}>
                    Reputation Management
                  </Typography>
                  <Typography component="li" sx={{
                    padding: "15px",
                    borderTop: "1px solid #ccc",
                    margin: '0px',
                    fontSize: '15px',
                  }}>
                    Branded Automated Rental Analysis
                  </Typography>
                  <Typography component="li" sx={{
                    padding: "15px",
                    borderTop: "1px solid #ccc",
                    margin: '0px',
                    fontSize: '15px',
                    color: '#000f14'
                  }}>
                    Comprehensive Monthly Reporting !
                  </Typography>
                  <Typography component="li" sx={{
                    padding: "15px",
                    borderTop: "1px solid #ccc",
                    margin: '0px',
                    fontSize: '15px',
                    color: '#000f14'
                  }}>
                    Reporting Dashboard
                  </Typography>
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box >
      {/* /================about=========================/ */}
      < Box sx={{
        borderBottom: '1px solid #eee',
      }}>
        <CardMedia image={Images.hote2} sx={{
          width: '100%',
          height: '60vh',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          backgroundAttachment: 'fixed',
          overflow: 'hidden',
        }
        }>
          <Box sx={{
            backgroundColor: 'rgba(21,21,21,.45)',
            width: '100%',
            height: '60vh',
            position: 'relative',
            overflow: 'hidden',

          }}>
            <Box sx={{
              width: '760px',
              height: '60vh',
              position: 'absolute',
              left: '0%',
              top: '0%',
              backgroundColor: 'rgba(0,128,128,0.6 )',
              transform: 'skew(-45deg)',
              transformOrigin: '100% 0',
            }}>
            </Box>
            <Grid item md={6}  >
              <Box sx={{
                position: 'absolute',
                display: 'inline-block',
                zIndex: 2,
                transform: 'translateY(-50%)',
                width: '34%',
                top: '50%',
                left: '3%',
              }}>
                <Typography component="h6" sx={{
                  fontSize: '50px',
                  textTransform: 'uppercase',
                  mb: '20px',
                  fontWeight: '500',
                  color: Colors.offwhite,
                }}>
                  register form
                </Typography>
                <Typography component="h6" sx={{
                  fontSize: '25px',
                  mb: '20px',
                  fontWeight: 'bold',
                  color: '#fff',
                }}>
                  Your Transformation Starts Here
                </Typography>
                <Typography component="h6" sx={{
                  fontSize: '20px',
                  mb: '20px',
                  mr: 2,
                  fontWeight: '400',
                  color: '#fff',
                }}>
                  Fill in your details and our sales team will be in touch with you.
                </Typography>
              </Box>
            </Grid>
            <Grid item md={6} sx={{
              padding: '20px',
            }}>
              <Box sx={{
                position: 'absolute',
                padding: "30px 20px",
                borderRadius: '10px',
                transform: 'translateY(-50%)',
                width: '35%',
                top: '50%',
                right: '3%',
                backgroundColor: Colors.white
              }}>
                <FormControl fullWidth sx={{
                  display: 'inline-block',
                  py: 2,
                  textAlign: "center",
                  color: 'black',
                }}>
                  <TextField sx={{
                    width: '70%'
                  }}
                    variant="outlined"
                    id="Standard "
                    label="Name"
                    type="phone"
                    autoComplete="off"
                  />
                </FormControl>
                <FormControl sx={{
                  display: 'block',
                  py: 2,
                  textAlign: "center",
                  color: 'black',

                }}>
                  <TextField sx={{
                    width: '70%'
                  }}
                    variant="outlined"
                    id="Standard "
                    label="Phone"
                    type="phone"
                    autoComplete="off"
                  />
                </FormControl>
                <FormControl sx={{
                  display: 'block',
                  py: 2,
                  textAlign: "center",
                  color: 'black',
                }}>
                  <TextField sx={{
                    width: '70%'
                  }}
                    variant="outlined"
                    id="Standard "
                    label="Email"
                    type="email"
                    autoComplete="off"
                  />
                </FormControl>

                <Box sx={{ justifyContent: 'center', display: 'flex', py: 2, }}>
                  <Button variant="outlned" sx={{
                    border: `1px solid ${Colors.black}`,
                    color: [Colors.black],
                    width: '70%',
                    margin: '0% auto',
                    '&:hover': {
                      border: `2px solid ${Colors.primary}`,
                      color: [Colors.white],
                      backgroundColor: Colors.primary
                    }
                  }}>
                    Register your interest
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Box>
          {/* <Box sx={{
            backgroundColor: 'rgba(0,128,128,0.4 )',
            width: '100%',
            height: '60vh',
            overflow: 'hidden',
          }}>
            <Grid container >
              <Grid item md={6} sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center'
              }}>
                <Typography component="p" sx={{
                  fontWeight: '500',
                  fontSize: '22px',
                  color: [Colors.black]
                }}>
                  Get personal advice with no obligation from us. We’ll show you how Takseemmobility platform works - and make sure you get the exact solution you really need.
                </Typography>

              </Grid>
            </Grid>
          </Box> */}
        </CardMedia>
      </Box >
    </Layout >
  )
};

export default Home