import { Box } from '@mui/material';
import React, { Fragment } from 'react'
import Header from './Header';
import Footer from './Footer';

export const Layout = ({ children }) => {
  return (
    <Fragment>

      {/* ========== Header ========== */}

      <Header />

      <Box >
        {children}
      </Box>

      {/* ========== Footer ========== */}

      <Footer />

    </Fragment>
  )
}