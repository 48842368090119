const Images = {
  logo: require("./logo.png"),
  banner1: require("../images/banner1.jpg"),
  banner2: require("../images/banner2.png"),
  banner3: require("../images/banner3.webp"),
  access: require("../images/access.png"),
  anncoument: require("../images/anncoument.png"),
  cafeteria: require("../images/cafeteria.png"),
  calender: require("../images/calender.png"),
  complain: require("../images/complain.png"),
  discussion: require("../images/discussion.png"),
  document: require("../images/document.png"),
  ebilling: require("../images/e-billing.png"),
  eform: require("../images/e-form.png"),
  epolling: require("../images/e-polling.png"),
  emergency: require("../images/emergency.png"),
  event: require("../images/event.png"),
  facility: require("../images/facility.png"),
  myresident: require("../images/my-resident.png"),
  tutriol: require("../images/tutriol.png"),
  visitor: require("../images/visitor.png"),
  hote2: require("../images/hotel-2.jpg"),
  playstore: require("../images/play-store.png"),
  appstore: require("../images/app-store.png"),
  service: require("../images/services.jpg"),
  phoneimg: require("../images/phone-image.png"),
  phoneimg1: require("../images/phone-2.jpg"),

};
export default Images;