const Colors = {
  primary: '#008080',
  primaryGradient: 'linear-gradient(180deg, #FF8B00 0%, #DA7B0A 100%)',
  secondary: '#e1f4fd',
  secondaryGradient: 'linear-gradient(180deg, #0184B9 0%, #046990 100%)',
  tertiary: '#aaa',
  textPrimary: '#2F2E41',
  textSecondary: '#2F2E4196',
  black: '#191d23',
  white: '#ffffff',
  offwhite: '#fafafa',
  danger: '#FF2121',
  success: '#79AA5A',
  gray: '#888',
  grayshade: '#3c3d41',
};

export default Colors;